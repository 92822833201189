<template>
  <v-dialog v-model="isModalContratOpen" width="99%" max-width="1000">
    <v-col>
      <v-card>
        <edit-contrat-form :seletedContrat="seletedContrat" :agenceListe="agenceListe" :retraitListe="retraitListe"
          :parcListe="parcListe"></edit-contrat-form>
      </v-card>
    </v-col>
  </v-dialog>
  <v-dialog v-model="isOrModalOpen" width="98%" max-width="900" persistent>
    <v-col>
      <v-card style="overflow: auto; max-height: 90vh;">
        <v-col>
          <contrat-form :get_user_information="get_user_information" :close="closeModal" :itemsData="itemsData"
            :technicienListe="technicienListe" :agenceListe="agenceListe" :retraitListe="retraitListe"
            :clientListe="clientListe" :parcListe="parcListe" :stockListe="stockListe" :FormData="savForm"
            :subFamilyListe="subFamilyListe" :familyListe="familyListe"
            :contrat_informations="contrat_informations"></contrat-form>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <div>
    <v-card class="pa-1 mx-1">
      <v-col>
        <v-row align="center">
          <v-col class="">
            <h3>Liste des contrats</h3>
          </v-col>
          <v-col cols="auto">
            <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white" @click="openDialog(undefined)">Créer un
              contrat
            </v-btn>
            <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="text" size="40"
              @click="getContratsListe" class="ml-3"></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field hide-details v-model="search" variant="outlined" label="Recherche" clearable></v-text-field>
      </v-col>
      <v-col>
        <Vue3EasyDataTable table-class-name="customize-table" ref="dataTable" :headers="homeHeader"
          :items="contratListe" style="overflow: auto" :table-height="windowHeight * 0.68"
          rows-per-page-message="Ligne par colonne" rows-of-page-separator-message="total" rows-per-page="50"
          empty-message="Aucune contrat trouvé" @click-row="setSelectedContrat">
          <template #item-sortie="item">
            <div>
              {{ formatDate(item['LocdataS_::Date sortie'], 'DD/MM/YYYY') }}
            </div>
          </template>
          <template #item-retour="item">
            <div>
              {{ formatDate(item['LocdataS_::Date retour'], 'DD/MM/YYYY') }}
            </div>
          </template>
          <template #item-facturation="item">
            <div align=center v-if="item.facturation && item.facturation.length > 0">
              <v-icon color="#66BB6A">
                mdi-check-circle
              </v-icon>
            </div>
            <div align=center v-else>
              <v-icon color="#EF6C00">
                mdi-clock-outline
              </v-icon>
            </div>
          </template>
          <!-- <template #item-selectionner="item">
            <div>
              <v-btn @click="setSelectedContrat(item)" color="#4CAF50" style="color:white">
                <v-icon size="25">
                  mdi-open-in-app
                </v-icon>
              </v-btn>
            </div>
          </template> -->
        </Vue3EasyDataTable>
      </v-col>
    </v-card>
  </div>
</template>

<script>

import Vue3EasyDataTable from "vue3-easy-data-table";

// edit-contrat-form
import contartHooks from '../hooks/contrat';
import editContratForm from './edit-contrat-form';
import ContratForm from './contrat-form.vue'
import moment from 'moment'

export default {
  data() {
    return {
      itemsData: undefined,
      isOrModalOpen: false,
      windowHeight: window.innerHeight,
      seletedContrat: undefined,
      isModalContratOpen: false,
    }
  },
  setup() {
    const { getContratsListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information } = contartHooks()

    return { getContratsListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format)
    },
    closeModal() {
      this.getContratsListe()
      this.isOrModalOpen = false
    },
    setSelectedContrat(item) {
      this.seletedContrat = item
      this.isModalContratOpen = true
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    openDialog(data) {
      this.itemsData = data
      this.isOrModalOpen = true
    }
  },
  computed: {
  },
  components: {
    ContratForm,
    editContratForm,
    Vue3EasyDataTable
  }
}
</script>

<style></style>