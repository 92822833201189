<template>
    <v-dialog v-model="isReplaceMaterielOpenVerif" max-width="300">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;" align=center>
                Valider ma modification
            </v-col>
            <v-col>
                <v-row justify="center" align="center">
                    <v-col cols="6" align="center">
                        <v-btn @click="function () {
                            isReplaceMaterielOpen = true
                            isReplaceMaterielOpenVerif = false
                        }" color="#F44336" style="color: white;">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-btn @click="changeMatricule" color="#4CAF50" style="color: white;">
                            Oui
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpen" max-width="500">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;">
                Sélectionner un nouveau matricule
            </v-col>
            <v-col>
                <v-autocomplete hide-details no-data-text="Aucun matricule trouvé" label="Matricule sélectionné"
                    v-model="selectedMatricule" :items="parcListe" item-value="MATRICULE" item-title="MATRICULE">
                </v-autocomplete>
            </v-col>
            <v-col class="pt-1" align="center">
                <v-btn color="#4CAF50" style="color: white;" @click="function () {
                    isReplaceMaterielOpen = false
                    isReplaceMaterielOpenVerif = true
                }">
                    Valider la modification
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-col style="max-height: 800px; overflow: auto;">
        <v-col>
            <v-row>
                <v-col cols="auto">
                    <div>Contrat : <span style="font-weight: bold;">{{ seletedContrat['N° Contrat Comp'] }}</span>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <div>Matricule : <span style="font-weight: bold;">{{ seletedContrat['LocdataS_::N° Mat interne']
                            }}</span></div>
                </v-col>
                <v-col cols="auto">
                    <div>Code client : <span style="font-weight: bold;">{{ seletedContrat['Code client']
                            }}</span></div>
                </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
                <v-col align=center>
                    <table style="background-color: #9E9E9E;width: 100%;">
                        <tr v-for="(elem, idx) in dataToShow" :key="idx"
                            :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#CFD8DC' }">
                            <td> {{ elem.label }}</td>
                            <td> {{ seletedContrat[elem.key] }}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-col>
        <v-col>
            <!-- <v-row>
                <v-col cols="">
                    <v-btn color="#90A4AE" style="color:white;width: 100%;" variant="flat">
                        Renouveller le contrat
                    </v-btn>
                </v-col>
                <v-col cols="">
                    <v-btn color="#90A4AE" style="color:white;width: 100%;" variant="flat">
                        Créer un ordre de préparation
                    </v-btn>
                </v-col>
                <v-col cols="">
                    <v-btn color="#90A4AE" @click="isReplaceMaterielOpen = true" style="color:white;width: 100%;"
                        variant="flat">
                        Remplacement de vélo
                    </v-btn>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col cols="auto">
                    <v-btn :href="'/#/etat-des-lieux-materiel?search=' + seletedContrat['N° Contrat Comp']"
                        variant="tonal" target="_blank" color="#1A237E">État
                        des lieux</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn :href="'/#/sav-gestion?search=' + seletedContrat['N° Contrat Comp']" color="#1B5E20"
                        variant="tonal" target="_blank">Sav</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn :href="'/#/signature-en-ligne?search=' + seletedContrat['Code client']" color="#B71C1C"
                        style="" variant="tonal" target="_blank">Signature des documents</v-btn>
                </v-col>
            </v-row>
            <v-col cols="12" class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat">
                    <v-col cols="12">
                        Statut du materiel
                    </v-col>
                    <v-radio-group inline>
                        <v-radio color="#FDD835" label="En attente" v-model="editableKey['statut']"
                            value="waiting"></v-radio>
                        <v-radio color="#F57C00" label="En préparation" v-model="editableKey['statut']"
                            value="gettingready"></v-radio>
                        <v-radio color="#4CAF50" label="Sortie" v-model="editableKey['statut']" value="out"></v-radio>
                        <v-radio color="#5C6BC0" label="Rentré" v-model="editableKey['statut']" value="in"></v-radio>
                        <!-- <v-radio color="#4CAF50" label="En stock" v-model="editableKey['statut']" value="stock"></v-radio>
                        <v-radio color="#5C6BC0" label="Sortie" v-model="editableKey['statut']" value="sortie"></v-radio> -->

                    </v-radio-group>
                </v-card>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>
export default {
    props: {
        seletedContrat: {},
        parcListe: {}
    },
    data() {
        return {
            isReplaceMaterielOpenVerif: false,
            isReplaceMaterielOpen: false,
            editableKey: ['LocdataS_::N° Mat interne'],
            editableData: {},
            dataToShow: [
                {
                    label: 'Client',
                    key: 'Societe',
                    type: 'text',
                },
                {
                    label: 'Type de contrat',
                    key: 'LocdataS_::ID_Grille_nom',
                    type: 'text',
                },
                {
                    label: 'Agence de retrait',
                    key: 'Lieu_retrait_nom',
                    type: 'text',
                },
                {
                    label: 'Date de sortie',
                    key: 'LocdataS_::Date Debut Loc',
                    type: 'date',
                    format: 'DD/MM/YYYY'
                },
                {
                    label: 'Date de retour',
                    key: 'LocdataS_::Date retour',
                    type: 'date',
                    format: 'DD/MM/YYYY'
                },
                {
                    label: 'Prix location TTC (€)',
                    key: 'Prix Location Total_TTC',
                    type: 'text',
                },
                {
                    label: 'Acompte (€)',
                    key: 'Acompte_final',
                    type: 'text',
                },
                {
                    label: 'Caution (€)',
                    key: 'caution_multiple::caution_texte',
                    type: 'text',
                },
                {
                    label: 'Etat de la caution',
                    key: 'caution_multiple::etat_caution',
                    type: 'text',
                },
            ]
        }
    },
    methods: {
        changeMatricule() {
            this.isReplaceMaterielOpenVerif = false
        }
    },
    mounted() {
        this.selectedMatricule = JSON.parse(JSON.stringify(this.seletedContrat['LocdataS_::N° Mat interne']))
    }
}
</script>

<style></style>